import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CarouselContainer = styled(Box)({
  position: "relative",
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
  overflow: "hidden",
  padding: "0 40px",
});

const CarouselTrack = styled(Box)<{ activeIndex: number }>(
  ({ activeIndex, theme }) => ({
    display: "flex",
    [theme.breakpoints.up("md")]: {
      transform: `translateX(-${activeIndex * (100 / 3)}%)`,
    },
    [theme.breakpoints.down("md")]: {
      transform: `translateX(-${activeIndex * 100}%)`,
    },
  })
);

const CarouselItem = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flex: "0 0 33.333%",
    width: "33.333%",
    padding: "0 8px",
  },
  [theme.breakpoints.down("md")]: {
    flex: "0 0 100%",
    width: "100%",
    padding: "0 4px",
  },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "300px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    height: "250px",
  },
  "& img": {
    width: "100%",
    height: "100%",
    display: "block",
    objectFit: "cover",
  },
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
  zIndex: 1,
  [theme.breakpoints.down("md")]: {
    width: "36px",
    height: "36px",
  },
  "&.prev": {
    left: 4,
  },
  "&.next": {
    right: 4,
  },
}));

interface CarouselItem {
  title: string;
  img: string;
  alt: string;
}

interface CustomCarouselProps {
  items: CarouselItem[];
}

export default function CustomCarousel({ items }: CustomCarouselProps) {
  const [activeIndex, setActiveIndex] = useState(items.length);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Create extended items array with copies before and after
  const extendedItems = [...items, ...items, ...items];

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    // Reset position when reaching cloned items
    if (activeIndex >= items.length * 2) {
      setActiveIndex(items.length);
    } else if (activeIndex < items.length) {
      setActiveIndex(items.length * 2 - 1);
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveIndex((prev) => prev + 1);
    }
  };

  return (
    <CarouselContainer>
      <NavigationButton
        className="prev"
        onClick={handlePrev}
        aria-label="Previous slide"
      >
        <ChevronLeftIcon />
      </NavigationButton>
      <NavigationButton
        className="next"
        onClick={handleNext}
        aria-label="Next slide"
      >
        <ChevronRightIcon />
      </NavigationButton>
      <CarouselTrack
        activeIndex={activeIndex}
        onTransitionEnd={handleTransitionEnd}
        sx={{
          // Remove transition when resetting position
          transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
        }}
      >
        {extendedItems.map((item, index) => (
          <CarouselItem key={index}>
            <ImageWrapper>
              <img src={item.img} alt={item.alt} loading="eager" />
            </ImageWrapper>
            <Typography
              style={{
                fontFamily: "Cinzel",
                marginTop: "1rem",
                textAlign: "center",
              }}
              variant="h6"
            >
              {item.title}
            </Typography>
          </CarouselItem>
        ))}
      </CarouselTrack>
    </CarouselContainer>
  );
}
