import React, { useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid, Typography } from "@material-ui/core";
import { Image } from "components/atoms";
import { CardProduct } from "components/organisms";
import { useTranslation } from "gatsby-plugin-react-i18next";
import image1 from "../../data/images/image1-200.jpg";
import image2 from "../../data/images/image2-200.jpg";
import image3 from "../../data/images/catering.png";

const useStyles = makeStyles((theme) => ({
  exploreMore: {
    position: "absolute",
    bottom: 0,
    right: 0,
    [theme.breakpoints.up("md")]: {
      right: 5,
      bottom: 5,
      padding: "3%",
    },
  },
  cardRoot: {
    position: "relative",
    borderRadius: "10px",
    width: "auto",
    maxWidth: "460px",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  cardImage: {
    position: "relative",
    width: "100%",
    minHeight: "200px",
    objectFit: "cover",
  },
}));

const Location = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const data = useMemo(() => {
    return [
      {
        text: t("featureText1"),
        image: image1,
      },
      {
        text: t("featureText2"),
        image: image2,
      },
      {
        text: t("featureText3"),
        image: image3,
      },
    ];
  }, []);

  return (
    <div>
      {/* <SectionHeader title={t('whatWeOfferTitle')} titleVariant="h3" fadeUp /> */}
      <Grid container spacing={2} justifyContent="center">
        {data.map((item: any, index: number) => {
          return (
            <Grid key={index} item xs={12} sm={6} md={3} data-aos="fade-up">
              <CardProduct
                withShadow
                liftUp
                className={classes.cardRoot}
                mediaContent={
                  <>
                    <Image
                      src={item.image}
                      className={classes.cardImage}
                      alt="Office View"
                    />
                  </>
                }
                cardContent={
                  <Grid container spacing={1} style={{ padding: "16px" }}>
                    {/* <Grid item xs={12} style={{ marginTop: -15 }}>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        align="left"
                        style={{
                          textTransform: 'uppercase',
                          fontFamily: 'Cinzel',
                          fontWeight: 400,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography
                        variant={currentLanguage === "en" ? "h6" : "body1"}
                        color="textPrimary"
                        align="left"
                      >
                        {item.text}
                      </Typography>
                    </Grid>
                    {/* <Grid item container justifyContent="flex-end" xs={12}>
                      <LearnMoreLink
                        color="secondary"
                        title={t('whatWeOfferExploreMore')}
                        href="/memberships"
                        className={classes.exploreMore}
                      />
                    </Grid> */}
                  </Grid>
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Location;
