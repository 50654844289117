import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  image: {
    borderRadius: '10px',
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08))',
    objectFit: 'cover',
    objectPosition: 'top left',
  },
  visitButton: {
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
      margin: 'auto 16px',
      height: '56px',
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: '134px',
    },
  },
  header: {
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
      maxWidth: '100%',
    },
  },
}));

const HeroCard = ({ text, image, revert, ...rest }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // const handleClick = () => {
  //   window[`scrollTo`]({ top: 0, behavior: `smooth` });
  // };

  const Text = ({text}): JSX.Element => {
    return (
      <Grid item container justifyContent='center' alignContent='center' xs={12} md={6} data-aos={'fade-up'}>
        <div>
          <SectionHeader
            // title={t('ourLocationTitle')}
            subtitle={text}
            align={isMd ? 'justify' : 'center'}
            titleVariant="h5"
            subtitleVariant="body1"
            subtitleColor="primary"
            className={classes.header}
          />
          {/* <div style={isMd ? {} : { textAlign: 'center' }}>
            <Button
              size={isMd ? 'large' : 'medium'}
              variant="contained"
              color="secondary"
              onClick={handleClick}
              className={classes.visitButton}
            >
              {t('ourLocationVisitButton')}
            </Button>
          </div> */}
        </div>
      </Grid>
    );
  };

  const HeroImage = ({image}): JSX.Element => {
    return (
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        md={6}
        data-aos={'fade-up'}
      >
        <Image
          src={image}
          alt="image"
          className={classes.image}
        />
      </Grid>
    );
  };

  return (
    <div {...rest} style={{paddingBottom: 48}}>
      {isMd ? (
        revert ?
        <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
          <HeroImage image={image}/>
          <Text text={text} />
        </Grid> 
        :
        <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
          <Text text={text} />
          <HeroImage image={image}/>
        </Grid>
      ) : (
        <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
          <HeroImage image={image}/>
          <Text  text={text}/>
        </Grid>
      )}
    </div>
  );
};

export default HeroCard;
