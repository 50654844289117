import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section } from 'components/organisms';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Grid, Typography } from '@material-ui/core';
import {
  Contact,
  HeroCard,
  Locations,
  EventFeaturesSection,
  Hero
} from './components';
import { data as myData } from './data';
import { DefaultSEO } from 'components/DefaultSEO';
import bar from './data/images/Bar.jpg';
import central from './data/images/CentralandKowloonView.jpg';
import spaceHigh from "./data/images/CENTER SPACE_WIDEANGLE_2_High Res_FINAL V1.jpg";


const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('md')]: {
    sectionAlternateForm: {
      maxWidth: '75%',
      margin: 'auto',
    },
  },
}));

const Event = (): JSX.Element => {
  const { t }  = useTranslation();
  const classes = useStyles();
  const { advantages, partners, locations } = myData();

  return (
    <div>
      <DefaultSEO
        title="THE CENTER SPACE | Flagship Business Lounge in Hong Kong | 坐落於中環核心的高端服務式辦公室"
        description="The Center is located at the heart of Central, Hong Kong's Central Business District. With a standing of 350 meters, The Center holds the title of Hong Kong’s fifth-highest skyscraper. Located on the 76th floor of the building, one of the highest co-working space in Hong Kong, our space offers panoramic views of Victoria Harbour and Hong Kong Skyline. The Center is also surrounded by major financial, banking, and government headquarters."
      />
      <Hero />
      <Section>
        <HeroCard text={t('eventText1')} image={central} revert={false}/>
        <HeroCard text={t('eventText2')} image={bar} revert={true}/>
        <HeroCard text={t('eventText3')} image={spaceHigh} revert={false}/>
        {/* <FeaturesSection /> */}
        <Locations />
        <EventFeaturesSection />
      </Section>
      <div className={classes.sectionAlternateForm} >
        <Section>
          <Contact id="contact-form" />
        </Section>
      </div>
    </div>
  );
};

export default Event;
