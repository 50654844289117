import { useTranslation } from 'gatsby-plugin-react-i18next';

export const data = () => {
  const { t } = useTranslation();
  const partners = [
    // {
    //   name: '',
    //   logo: sheraton,
    // },
    // {
    //   name: '',
    //   logo: hyatt,
    // },
    // {
    //   name: '',
    //   logo: intercontinental,
    // },
    // {
    //   name: '',
    //   logo: hilton,
    // },
    // {
    //   name: '',
    //   logo: yutopia,
    // },
    // {
    //   name: '',
    //   logo: mworks,
    // },
    // {
    //   name: '',
    //   logo: conrad,
    // },
    // {
    //   name: '',
    //   logo: yuhotels,
    // },
    // {
    //   name: '',
    //   logo: ethos,
    // },
    // {
    //   name: '',
    //   logo: minimax,
    // },
    // {
    //   name: '',
    //   logo: yuluxe,
    // },
    // {
    //   name: '',
    //   logo: boxful,
    // },
  ];

  const advantages = [
    // {
    //   icon: serviced,
    //   title: t('whatUniqueServicedTitle'),
    //   subtitle: t('whatUniqueServicedSubtitle'),
    // },
    // {
    //   icon: flexible,
    //   title: t('whatUniqueFlexibleTitle'),
    //   subtitle: t('whatUniqueFlexibleSubtitle'),
    // },
    // {
    //   icon: experience,
    //   title: t('whatUniqueExperienceTitle'),
    //   subtitle: t('whatUniqueExperienceSubtitle'),
    // },
  ];

  const locations = [
    // {
    //   title: t('whatWeOfferPrivateOfficesTitle'),
    //   address: t('whatWeOfferPrivateOfficesContent'),
    //   image: privateoffice,
    // },
    // {
    //   title: t('whatWeOfferCoworkingTitle'),
    //   address: t('whatWeOfferCoworkingContent'),
    //   image: coworking,
    // },
    
    // {
    //   title: t('whatWeOfferVirtualOfficesTitle'),
    //   address: t('whatWeOfferVirtualOfficesContent'),
    //   image: virtualoffice,
    // },
    // {
    //   title: t('whatWeOfferEventsMeetingTitle'),
    //   address: t('whatWeOfferEventsMeetingContent'),
    //   image: eventmeetingspace,
    // },
  ];

  return {
    partners,
    advantages,
    locations,
  };
};
