import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { styled } from "@mui/material/styles";
import image1 from "../../data/images/silder_Corporate Cocktail Party.jpeg";
import image2 from "../../data/images/slider_corporate traning.png";
import image3 from "../../data/images/slider_meetings.jpeg";
import image4 from "../../data/images/slider_seminars.png";
import image5 from "../../data/images/video.png";
import image6 from "../../data/images/slider_workshop.jpg";
import CustomCarousel from "./CustomCarousel";

const SectionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

export default function EventFeaturesSection() {
  const { t } = useTranslation();
  const items = [
    {
      title: t("eventTitle1"),
      img: image2,
      alt: "Corporate Training",
    },
    {
      title: t("eventTitle2"),
      img: image4,
      alt: "Seminars",
    },
    {
      title: t("eventTitle3"),
      img: image6,
      alt: "Workshops",
    },
    {
      title: t("eventTitle4"),
      img: image3,
      alt: "Meetings Venue",
    },
    {
      title: t("eventTitle5"),
      img: image5,
      alt: "Video shootings",
    },
    {
      title: t("eventTitle6"),
      img: image1,
      alt: "Cocktail Party",
    },
  ];

  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          gutterBottom
          marginTop={8}
          marginBottom={4}
          style={{
            textTransform: "uppercase",
            fontFamily: "Cinzel",
            fontWeight: 400,
          }}
        >
          {t("eventFeaturesHeading")}
        </Typography>
        <CustomCarousel items={items} />
      </Container>
    </SectionContainer>
  );
}
