import React from 'react';
import { Box, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Text from './Text';
import bar from '../../data/images/Bar.jpg';
import central from '../../data/images/CentralandKowloonView.jpg';
import spaceHigh from "../../data/images/CENTER SPACE_WIDEANGLE_2_High Res_FINAL V1.jpg";

const HeroContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <HeroContainer>
      <Container maxWidth="lg">
        <Text text={t('eventHeading1')} variant="h3" component="h1" />
        <Grid container alignItems="flex-start">
          <Grid 
            item 
            xs={12} 
            md={6} 
            order={{ xs: 1, md: 2 }}
          >
            <img
              src={central}
              alt="Geometric windows showing Hong Kong skyline"
              width={'100%'}
              height={'auto'}
            />
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={6} 
            order={{ xs: 2, md: 1 }}
            paddingLeft={'1rem'}
            paddingRight={'1rem'}
          >
            <Text text={t('eventText1')} />
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start">
          <Grid 
            item 
            xs={12} 
            md={6} 
            order={{ xs: 1, md: 1 }}
          >
            <img
              src={bar}
              alt="Bar"
              width={'100%'}
              height={'auto'}
            />
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={6} 
            order={{ xs: 2, md: 2 }}
            paddingLeft={'1rem'}
            paddingRight={'1rem'}
          >
            <Text text={t('eventText3')} />
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start">
          <Grid 
            item 
            xs={12} 
            md={6} 
            order={{ xs: 1, md: 2 }}
          >
            <img
              src={spaceHigh}
              alt="Geometric windows showing Hong Kong skyline"
              width={'100%'}
              height={'auto'}
            />
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={6} 
            order={{ xs: 2, md: 1 }}
            paddingLeft={'1rem'}
            paddingRight={'1rem'}
          >
            <Text text={t('eventText3')} />
          </Grid>
        </Grid>
      </Container>
    </HeroContainer>
  );
}
