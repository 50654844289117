import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface TextProps {
  text: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
  component?: React.ElementType;
}

const TextContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export default function Text({ 
  text,
  variant = 'body1',
  component
}: TextProps) {
  return (
    <TextContainer>
      <Typography variant={variant} component={component} gutterBottom>
        {text}
      </Typography>
    </TextContainer>
  );
}

